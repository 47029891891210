import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import { getAllProducts } from "actions/products";
import { useState, useEffect } from "react";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCard2 from "components/SectionSliderProductCard2";
import Img1 from "images/expe1.png";
import Img2 from "images/SERVICEpaiementsécurisé.png";
import Img3 from "images/SERVICEsurmesure1.png";
import Img4 from "images/SERVICEfaitmainetsain.png";
import Img5 from "images/images-cococinelle.png";

import Ico1 from "images/chat.png";
import Ico2 from "images/picto-reassurance-01.svg"
import Ico3 from "images/ring-phone.png"
import Facebook from "images/facebook.png"

import ButtonFour from "shared/Button/ButtonFour";
import { useHistory } from "react-router-dom";

const PageHome = () => {
  const [allProducts, setAllProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAllProducts().then((res: any) => {
      setAllProducts(res)
    })

  }, [])

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Accueil - CococinelleCococinelle</title>
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />

      {/* SECTION HOW IT WORK */}
      <SectionHowItWork />

      <div>
        {/* SLIDER ALL PRODUCTS */}
        {allProducts.length !== 0 &&
          <SectionSliderProductCard
            heading="Coups de coeur"
            data={allProducts}
          />
        }
      </div>

      <div className="container py-10">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 place-items-center gap-6 ">
          <img className="flex w-full h-full" src={Img1} />
          <img className="flex w-full h-full" src={Img2} />
          <img className="flex w-full h-full" src={Img3} />
          <img className="flex w-full h-full" src={Img4} />
        </div>
      </div>


      <div>
        {/* SLIDER ALL PRODUCTS */}
        {allProducts.length !== 0 &&
          <SectionSliderProductCard2
            heading="Nouveautés"
            data={allProducts}
          />
        }
      </div>

      <div className="py-6">
        <div className="bg-[#FF6B5B] py-10">
          <div className="container text-[white]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 xl:grid-cols-2 h-full">
              <div className="flex items-center font-mulish">
                <div className="text-[18px] leading-[27px]">
                  <div className="mb-8">
                    <h1 className="font-semibold text-[25px] mb-8">Cococinelle Créa : L'Art de l'Amour en Matière Bio pour les Plus Petits</h1>
                    <p className="text-[17px]">Au cœur de l'univers des vêtements et accessoires en matière bio, Cococinelle Créa se distingue par son engagement envers les prématurés, les bébés, et les enfants. Notre société incarne la fusion parfaite entre la créativité et la durabilité, offrant une gamme de produits conçus pour répondre aux besoins spécifiques des plus petits tout en préservant la planète.</p>
                    <p className="text-[17px] mt-4">Nos créations pour bébés et enfants combinent le confort et le style. De ravissants motifs et des couleurs vives donnent vie à nos vêtements et accessoires, tout en respectant notre engagement envers la durabilité. Chez Cococinelle Créa, nous pensons que la mode ne doit pas sacrifier la planète. C'est pourquoi nous utilisons des méthodes de production respectueuses de l'environnement, minimisant les déchets et réduisant notre empreinte carbone.</p>
                    <div className="mt-4">
                      <ButtonFour onClick={() => history.push("/page/a-propos")}>En savoir plus</ButtonFour>
                    </div>
                  </div>
                </div>
              </div>
              <img className="flex place-items-center" src={Img5} />
            </div>
          </div>
        </div>
      </div>


      {/* OUR CONTACT */}
      <div className="py-16">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-10 md:gap-10 lg:gap-10 xl:gap-10">
            <iframe className="flex w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.125660959499!2d0.7266882769977044!3d44.41006707107633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ab07915e792179%3A0x175752541940da58!2s14%20Rue%20Andr%C3%A9%20Malraux%2C%2047300%20Villeneuve-sur-Lot!5e0!3m2!1sfr!2sfr!4v1697618056779!5m2!1sfr!2sfr" width="600" height="450" loading="lazy"></iframe>
            <div>
              <h2 className="text-center font-oswald text-[#FF6B5B] text-[20px] font-[500]">CONTACTEZ-NOUS</h2>
              <h1 className="text-center font-oswald mt-5 text-[40px] font-[500] text-[#716868] wavy leading-[45px]">Échangez avec Cococinelle</h1>

              <div className="mt-10 px-4 md:px-24 lg:px-24 xl:px-24 font-[500]">


                <div className="flex mb-8">
                  <img src={Ico1} />
                  <div className="ml-7">
                    <h1>cococinelle.crea@gmail.com</h1>
                    <div className="flex flex-row justify-center">
                      <img onClick={() => window.open('https://www.facebook.com/cococinelle.crea/')} className="cursor-pointer" src={Facebook} />
                    </div>
                  </div>
                </div>


                <div className="flex mb-8">
                  <img src={Ico2} />
                  <div className="ml-7">
                    <h1>14 RUE ANDRE MALRAUX, Villeneuve-sur-Lot,</h1>
                    <h1>Nouvelle-Aquitaine, France, 47300</h1>
                  </div>
                </div>

                <div className="flex mb-8 h-full">
                  <img src={Ico3} />
                  <div className="ml-7 flex items-center">
                    <h1><a className="text-[#756969] font-[500] hover:font-[600] " href="tel:+33564580005">06 26 35 44 35</a></h1>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PageHome;
