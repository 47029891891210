import { Helmet } from "react-helmet";

const MentionsLegales = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Mentions légales | CococinelleCococinelle</title>
            </Helmet>

            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-juliu leading-[60px] text-[45px] mb-4 text-[#FF6B5B]">Mentions légales</h1>
                    </div>

                    <div className="max-w-7xl mx-auto mt-14 text-[#555]">
                        <h1 className="text-3xl font-bold mb-4 text-[#FF6B5B]">Identité</h1>
                        <div className="space-y-4 ">
                            <div>
                                <span className="font-semibold">Nom du site web</span> : Cococinelle.com
                            </div>
                            <div>
                                <span className="font-semibold">Adresse</span> : 14 RUE ANDRE MALRAUX 47300 Villeneuve-sur-Lot, Nouvelle-Aquitaine
                            </div>

                            <div>
                                <span className="font-semibold">Propriétaire</span> :  Cococinelle, Auto entrepreneur
                            </div>

                            <div>
                                <span className="font-semibold">Responsable de publication</span> : Mme Gwénaëlle Roux Thorigné-Bouscarle
                            </div>
                        </div>

                        <div className="space-y-4 mt-8">
                            <div>
                                <span className="font-semibold">Conception et réalisation</span> : Milango
                            </div>
                            <div>
                                <span className="font-semibold">Animation</span> : Mme Gwénaëlle Roux Thorigné-Bouscarle
                            </div>
                            <div>
                                <span className="font-semibold">Hébergement</span> : Milango
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Personne morale</h1>
                        <div className="space-y-4">
                            <div>
                                Cococinelle - Auto entrepreneur
                            </div>
                            <div>
                                14 RUE ANDRE MALRAUX 47300 Villeneuve-sur-Lot, Nouvelle-Aquitaine
                            </div>
                            <div>
                                Email : cococinelle.crea@gmail.com
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Conditions d’utilisation</h1>
                        <div className="space-y-4">
                            <div>
                                L’utilisation du présent site implique l’acceptation pleine et entière des conditions générales d’utilisation décrites ci-après. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment.
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Informations</h1>
                        <div className="space-y-4">
                            <div>
                                Les informations et documents du site sont présentés à titre indicatif, n’ont pas de caractère exhaustif, et ne peuvent engager la responsabilité du propriétaire du site.
                            </div>
                            <div>
                                Le propriétaire du site ne peut être tenu responsable des dommages directs et indirects consécutifs à l’accès au site.
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Interactivité</h1>
                        <div className="space-y-4">
                            <div>
                                Les utilisateurs du site peuvent y déposer du contenu, apparaissant sur le site dans des espaces dédiés (notamment via les commentaires). Le contenu déposé reste sous la responsabilité de leurs auteurs, qui en assument pleinement l’entière responsabilité juridique.
                            </div>
                            <div>
                                Le propriétaire du site se réserve néanmoins le droit de retirer sans préavis et sans justification tout contenu déposé par les utilisateurs qui ne satisferait pas à la charte déontologique du site ou à la législation en vigueur.
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Propriété intellectuelle</h1>
                        <div className="space-y-4">
                            <div>
                                Sauf mention contraire, tous les éléments accessibles sur le site (textes, images, graphismes, logo, icônes, sons, logiciels, etc.) restent la propriété exclusive de leurs auteurs, en ce qui concerne les droits de propriété intellectuelle ou les droits d’usage.
                            </div>
                            <div>
                                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l’auteur.
                            </div>
                            <div>
                                Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient est considérée comme constitutive d’une contrefaçon et poursuivie.
                            </div>
                            <div>
                                Les marques et logos reproduits sur le site sont déposés par les sociétés qui en sont propriétaires.
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Liens</h1>
                        <div className="space-y-4">
                            <div>
                                <span className="font-semibold">Lien sortants<br /></span>
                                <h1 className="mt-2">Le propriétaire du site décline toute responsabilité et n’est pas engagé par le référencement via des liens hypertextes, de ressources tierces présentes sur le réseau Internet, tant en ce qui concerne leur contenu que leur pertinence.</h1>
                            </div>

                            <div>
                                <span className="font-semibold">Lien entrants<br /></span>
                                <h1 className="mt-2">Le propriétaire du site autorise les liens hypertextes vers l’une des pages de ce site, à condition que ceux-ci ouvrent une nouvelle fenêtre et soient présentés de manière non équivoque afin d’éviter :</h1>

                                <ul className="list-disc ml-12 mt-3 mb-3">
                                    <li>tout risque de confusion entre le site citant et le propriétaire du site</li>
                                    <li>ainsi que toute présentation tendancieuse, ou contraire aux lois en vigueur.</li>
                                </ul>

                                <h1>Le propriétaire du site se réserve le droit de demander la suppression d’un lien s’il estime que le site source ne respecte pas les règles ainsi définies.</h1>
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Confidentialité</h1>
                        <div className="space-y-4">
                            <div>
                                Tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une preuve d’identité.
                            </div>
                            <div>
                                Le site n’est pas assujetti à déclaration à la CNIL.
                            </div>
                        </div>

                        <h1 className="text-3xl font-bold mb-4 mt-8 text-[#FF6B5B]">Crédits</h1>
                        <div className="space-y-4">
                            <div>
                                Cococinelle, Milango.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MentionsLegales;
