
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import img1 from '../../images/img_about_1.png'
import img2 from '../../images/img_about_2.png'
import img3 from '../../images/img_about_3.png'
import ButtonPrimary from "shared/Button/ButtonPrimary";

const APropos = () => {
    const history = useHistory();

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>A propos - CococinelleCococinelle</title>
            </Helmet>

            <div className="bg-[#FF6B5B] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[white]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[white]">{">"}</span>
                    <h1 className="text-[white]">A propos</h1>
                </div>
            </div>
            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">

                            <div className="flex justify-center">
                                <h1 className="text-[#725E39] font-[500] text-[33px]">A propos</h1>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 lg:grid-cols-3 xl:grid-cols-3 mt-16">
                                <img className='flex h-full w-full' src={img1} />
                                <img className='flex h-full w-full' src={img2} />
                                <img className='flex h-full w-full' src={img3} />
                            </div>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500] mt-10">Nous avons bien conscience du prix que génère le Bio dans l’habillement.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Ainsi, nous partons de deux postulats indiscutables : le premier étant que la santé n’a pas de prix et le deuxième étant qu’en se démocratisant le commerce bio tendra vers un équilibre économique.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">En attendant, nous vous proposons des produits aux justes prix, au savoir-faire artisanal & respectueux de l’environnement.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Nous créons, dessinons et fabriquons les modèles présentés, en utilisant exclusivement des produits naturels et des tissus issus de la culture biologique certifiée.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Cultivé sans pesticide ni engrais chimique et récolté à la main, le coton biologique contribue au bien-être de ceux qui les utilisent et le cultive. Les pratiques de production ne polluent ni l’eau, ni le sol ni l’air.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Anallergiques, souples et doux au toucher, nos vêtements veillent au bien-être de votre bébé en protégeant sa peau sensible.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">En achetant des habits en coton biologique, vous encouragez la fabrication d’habits plus sûrs et plus sains pour votre bébé.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Nous limitons nos déplacements & travaillons au maximum depuis notre domicile pour éviter tous les surcoûts, les dépenses énergétiques et la pollution.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">En cosmétique bio, nous préférons les produits naturels avec des composants végétaux et nous prohibons les tests sur animaux.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Nous nous sommes aperçus que les certifications ne sont pas des garanties à 100%. Certaines marques n’en ont aucune alors que tous leurs produits présentent des caractéristiques excellentes pour l’homme et l’environnement. Aussi, nous nous efforçons de vérifier par nous-même les modes et procédés de fabrications des produits que nous proposons.</p>

                            <p className="text-[18px] leading-[28px] mt-6 text-[#555] font-[500]">Nous excluons de nos sélections tous les produits qui sont à l’origine de la destruction d’une partie de notre planète ou de la destruction de peuplades ou tribus.</p>

                            <div className="flex justify-center mt-6">
                                <ButtonPrimary onClick={() => history.push({ pathname: '/contact' })}>Contactez-moi</ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default APropos;
