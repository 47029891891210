
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const TableauxDesMesures = () => {
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Tableaux des mesures - CococinelleCococinelle</title>
            </Helmet>

            <div className="bg-[#FF6B5B] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[white]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[white]">{">"}</span>
                    <h1 className="text-[white]">Tableaux des mesures</h1>
                </div>
            </div>
            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">

                            <div className="py-10">
                                <div className="flex justify-center">
                                    <h1 className="text-[#725E39] font-[500] text-[33px]">Tableau de mesure – Prématuré</h1>
                                </div>

                                <div className="mt-10">
                                    <tbody className="text-sm">
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Nombre de semaines de gestation</td>
                                            <td className="border border-black w-1/4 text-center">34</td>
                                            <td className="border border-black w-1/4 text-center">40</td>
                                            <td className="border border-black w-1/4 text-center">Terme</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Longueur Total (en cm)</td>
                                            <td className="border border-black w-1/4 text-center">45</td>
                                            <td className="border border-black w-1/4 text-center">50</td>
                                            <td className="border border-black w-1/4 text-center">54</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Poids (en gramme)</td>
                                            <td className="border border-black w-1/4 text-center">2138</td>
                                            <td className="border border-black w-1/4 text-center">3420</td>
                                            <td className="border border-black w-1/4 text-center">4000</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Tour de tête (en cm)</td>
                                            <td className="border border-black w-1/4 text-center">31</td>
                                            <td className="border border-black w-1/4 text-center">34.5</td>
                                            <td className="border border-black w-1/4 text-center">41</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Longueur Pieds (en cm)</td>
                                            <td className="border border-black w-1/4 text-center">7.6</td>
                                            <td className="border border-black w-1/4 text-center">8.9</td>
                                            <td className="border border-black w-1/4 text-center">9.4</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/4 text-center font-bold">Taille à commander</td>
                                            <td className="border border-black w-1/4 text-center"><span className="text-purple-500 font-bold">00 mois</span></td>
                                            <td className="border border-black w-1/4 text-center font-bold"><span className="text-purple-500">0 mois</span></td>
                                            <td className="border border-black w-1/4 text-center"><span className="text-purple-500 font-bold">1 mois</span></td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>

                            <div className="py-10">
                                <div className="flex justify-center">
                                    <h1 className="text-[#725E39] font-[500] text-[33px]">Tableau de mesure – Bébé</h1>
                                </div>
                                <div className="mt-10">
                                    <tbody className="text-sm">
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Stature</td>
                                            <td className="border border-black w-1/5 text-center">60</td>
                                            <td className="border border-black w-1/5 text-center">67</td>
                                            <td className="border border-black w-1/5 text-center">74</td>
                                            <td className="border border-black w-1/5 text-center">81</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Tour de poitrine</td>
                                            <td className="border border-black w-1/5 text-center">43</td>
                                            <td className="border border-black w-1/5 text-center">46</td>
                                            <td className="border border-black w-1/5 text-center">49</td>
                                            <td className="border border-black w-1/5 text-center">50</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Longueur du bras</td>
                                            <td className="border border-black w-1/5 text-center">20</td>
                                            <td className="border border-black w-1/5 text-center">22.50</td>
                                            <td className="border border-black w-1/5 text-center">25</td>
                                            <td className="border border-black w-1/5 text-center">27.5</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Tour de taille</td>
                                            <td className="border border-black w-1/5 text-center">40</td>
                                            <td className="border border-black w-1/5 text-center">44</td>
                                            <td className="border border-black w-1/5 text-center">48</td>
                                            <td className="border border-black w-1/5 text-center">56</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Hauteur latéral Taille/Terre</td>
                                            <td className="border border-black w-1/5 text-center">31.50</td>
                                            <td className="border border-black w-1/5 text-center">37</td>
                                            <td className="border border-black w-1/5 text-center">42.50</td>
                                            <td className="border border-black w-1/5 text-center">48</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Hauteur Taille/Genoux</td>
                                            <td className="border border-black w-1/5 text-center">17</td>
                                            <td className="border border-black w-1/5 text-center">20</td>
                                            <td className="border border-black w-1/5 text-center">23</td>
                                            <td className="border border-black w-1/5 text-center">26</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Longueur du pied</td>
                                            <td className="border border-black w-1/5 text-center">10</td>
                                            <td className="border border-black w-1/5 text-center">11</td>
                                            <td className="border border-black w-1/5 text-center">12</td>
                                            <td className="border border-black w-1/5 text-center">13</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Périmètre cranien</td>
                                            <td className="border border-black w-1/5 text-center">43</td>
                                            <td className="border border-black w-1/5 text-center">45</td>
                                            <td className="border border-black w-1/5 text-center">47</td>
                                            <td className="border border-black w-1/5 text-center">49</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Taille à commander</td>
                                            <td className="border border-black w-1/5 text-center"><span className="text-purple-500 font-bold">3 mois</span></td>
                                            <td className="border border-black w-1/5 text-center font-bold"><span className="text-purple-500">6 mois</span></td>
                                            <td className="border border-black w-1/5 text-center"><span className="text-purple-500 font-bold">12 mois</span></td>
                                            <td className="border border-black w-1/5 text-center"><span className="text-purple-500 font-bold">18 mois</span></td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>


                            <div className="py-10">
                                <div className="flex justify-center">
                                    <h1 className="text-[#725E39] font-[500] text-[33px]">Tableau de mesure – Enfant</h1>
                                </div>

                                <div className="mt-10">

                                    <tbody className="text-sm">
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Stature</td>
                                            <td className="border border-black w-1/6 text-center">86</td>
                                            <td className="border border-black w-1/6 text-center">98</td>
                                            <td className="border border-black w-1/6 text-center">104</td>
                                            <td className="border border-black w-1/6 text-center">110</td>
                                            <td className="border border-black w-1/6 text-center">116</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Tour de poitrine</td>
                                            <td className="border border-black w-1/6 text-center">52</td>
                                            <td className="border border-black w-1/6 text-center">54</td>
                                            <td className="border border-black w-1/6 text-center">56</td>
                                            <td className="border border-black w-1/6 text-center">58</td>
                                            <td className="border border-black w-1/6 text-center">60</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Longueur du bras</td>
                                            <td className="border border-black w-1/6 text-center">30</td>
                                            <td className="border border-black w-1/6 text-center">33</td>
                                            <td className="border border-black w-1/6 text-center">36</td>
                                            <td className="border border-black w-1/6 text-center">38</td>
                                            <td className="border border-black w-1/6 text-center">40</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Tour de taille</td>
                                            <td className="border border-black w-1/6 text-center">48</td>
                                            <td className="border border-black w-1/6 text-center">50</td>
                                            <td className="border border-black w-1/6 text-center">52</td>
                                            <td className="border border-black w-1/6 text-center">53</td>
                                            <td className="border border-black w-1/6 text-center">54</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Hauteur latéral Taille/Terre</td>
                                            <td className="border border-black w-1/6 text-center">53.50</td>
                                            <td className="border border-black w-1/6 text-center">58.25</td>
                                            <td className="border border-black w-1/6 text-center">63</td>
                                            <td className="border border-black w-1/6 text-center">67</td>
                                            <td className="border border-black w-1/6 text-center">71.50</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Hauteur Taille/Genoux</td>
                                            <td className="border border-black w-1/6 text-center">29</td>
                                            <td className="border border-black w-1/6 text-center">32</td>
                                            <td className="border border-black w-1/6 text-center">35</td>
                                            <td className="border border-black w-1/6 text-center">37</td>
                                            <td className="border border-black w-1/6 text-center">39</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Périmètre cranien</td>
                                            <td className="border border-black w-1/6 text-center">51</td>
                                            <td className="border border-black w-1/6 text-center">52</td>
                                            <td className="border border-black w-1/6 text-center">53</td>
                                            <td className="border border-black w-1/6 text-center">53</td>
                                            <td className="border border-black w-1/6 text-center">54</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black w-1/5 text-center font-bold">Taille à commander</td>
                                            <td className="border border-black w-1/6 text-center"><span className="text-purple-500 font-bold">2 ans</span></td>
                                            <td className="border border-black w-1/6 text-center"><span className="text-purple-500 font-bold">3 ans</span></td>
                                            <td className="border border-black w-1/6 text-center"><span className="text-purple-500 font-bold">4 ans</span></td>
                                            <td className="border border-black w-1/6 text-center"><span className="text-purple-500 font-bold">5 ans</span></td>
                                            <td className="border border-black w-1/6 text-center"><span className="text-purple-500 font-bold">6 ans</span></td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TableauxDesMesures;
