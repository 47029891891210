import { FC, useState } from "react";

import img1 from "images/SLIDERfondbienvenue.png";

import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { COLORS } from "config/theme";

interface HeroDataType {
    image: string;
    heading: string;
    subHeading: string;
    btnLink: string;
}
export interface SectionHeroProps {
    className?: string;
}

const DATA: HeroDataType[] = [
    {
        image: img1,
        heading: "",
        subHeading: "",
        btnLink: "/",
    }
];

let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
    // =================
    const [indexActive, setIndexActive] = useState(0);
    const [isRunning, toggleIsRunning] = useBoolean(true);

    useInterval(
        () => {
            handleAutoNext();
        },
        isRunning ? 5500 : null
    );


    const handleAutoNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
        handleAfterClick();
    };

    const handleClickPrev = () => {
        setIndexActive((state) => {
            if (state === 0) {
                return DATA.length - 1;
            }
            return state - 1;
        });
        handleAfterClick();
    };

    const handleAfterClick = () => {
        toggleIsRunning(false);
        if (TIME_OUT) {
            clearTimeout(TIME_OUT);
        }
        TIME_OUT = setTimeout(() => {
            toggleIsRunning(true);
        }, 1000);
    };

    const renderItem = (index: number) => {
        const isActive = indexActive === index;

        const item = DATA[index];
        if (!isActive) {
            return null;
        }

        return (
            <div
                className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
                key={index}
            >

                {/* BG */}
                <div className="absolute inset-0" style={{ backgroundColor: COLORS?.sliderBg }}>

                </div>

                <div className="lg:container md:container md:mt-10 md:mb-20 lg:mt-10 lg:mb-10">
                    <div className="w-full relative">
                        <img
                            className="w-full h-full xs:h-[500px] object-cover nc-SectionHero2Item__image"
                            src={item.image}
                            alt={item.heading}
                        />

                        <div style={{ backgroundColor: COLORS?.sliderBgText }} className={`absolute xs:w-2/3 lg:right-[100px] md:right-[100px] opacity-[.85] absolute top-1/2 xs:left-1/2 sm:left-1/2 xs:transform sm:transform xs:-translate-x-1/2 sm:-translate-x-1/2 -translate-y-1/2 ${item?.heading ? "block" : "hidden"}`}>
                            <div className="lg:p-6 md:p-6 xs:p-8 sm:p-3">
                                <h1 style={{ color: COLORS?.headingCaroussel }} className="lg:text-[30px] xs:mb-6 sm:mb-3 md:text-[23px] xs:text-[32px] sm:text-[26px] sm:leading-[30px] xs:leading-[35px] text-center lg:leading-[40px]">{item?.heading}</h1>
                                <h2 style={{ color: COLORS?.paragraphCaroussel }} className="text-center xs:text-[20px] sm:text-[16px] lg:text-[18px] md:text-[16px] lg:mt-4">{item?.subHeading}</h2>
                            </div>
                        </div>
                        
                        <Prev
                            className={`absolute text-[white] lg:left-[10px] md:left-[10px] opacity-[.85] absolute top-1/2 xs:left-[37px] sm:left-14 xs:transform sm:transform xs:-translate-x-1/2 sm:-translate-x-1/2 -translate-y-1/2 ${DATA?.length === 1 ? "hidden" : "block"}`}
                            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                            svgSize="w-10 h-10"
                            onClickPrev={handleClickPrev}
                        />
                        <Next
                            className={`absolute text-[white] lg:right-[10px] md:right-[10px] opacity-[.85] absolute top-1/2 xs:right-[-10px] sm:right-1 xs:transform sm:transform xs:-translate-x-1/2 sm:-translate-x-1/2 -translate-y-1/2 ${DATA?.length === 1 ? "hidden" : "block"}`}
                            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                            svgSize="w-10 h-10"
                            onClickNext={handleClickNext}
                        />

                        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
                            {DATA.map((_, index) => {
                                const isActive = indexActive === index;
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setIndexActive(index);
                                            handleAfterClick();
                                        }}
                                        className={`relative px-1 py-1.5 cursor-pointer`}
                                    >
                                        <div
                                            className={`${DATA?.length === 1 ? "hidden" : "block"} relative w-20 h-1 shadow-sm rounded-md bg-white`}
                                        >
                                            {isActive && (
                                                <div
                                                    className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${isActive ? "bg-[#ff6B5B]" : " "
                                                        }`}
                                                ></div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero;
