export const COLORS = {


    preHeader: "#F7ECDB",
    preHeaderText: "#FF6B5B",

    // NAVBAR 
    navbar: 'white',
    navbarText: 'black',
    navbarTextHover: '#1e6132',
    navbarArrow: 'black',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "white",

    // FOOTER
    footer: '#0A0A0A',
    footerText: 'white',


    // BUTTON
    buttonPrimary: '#FF6B5B',
    buttonSecondary: '#FF6B5B',
    buttonThird: '#FF6B5B',
    buttonFour: 'white',
    filterBtn: '#6E5D4A',

    // INPUT
    searchInputBorder: 'white',

    heading: '#FF6B5B',
    text: '#FF6B5B',
    headingCaroussel: "#FF6B5B",
    paragraphCaroussel: "#FF6B5B",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '#FF6B5B',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonPrimary: '0px',
    buttonSecondary: '0px',
    buttonVariant: '1px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    buttonThird: '3px',
    buttonFour: '3px',
    input: ''
}
