import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Ico1 from "images/chat.png";
import Ico2 from "images/picto-reassurance-01.svg"
import Ico3 from "images/ring-phone.png"
import Facebook from "images/facebook.png"

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Me contacter - CococinelleCococinelle</title>
            </Helmet>

            <div className="bg-[#FF6B5B] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[white]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[white]">{">"}</span>
                    <h1 className="text-[white]">Nous contacter</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>


                        <div className="py-16">
                            <div className="container">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-10 md:gap-10 lg:gap-10 xl:gap-10">
                                    <iframe className="flex w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.125660959499!2d0.7266882769977044!3d44.41006707107633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ab07915e792179%3A0x175752541940da58!2s14%20Rue%20Andr%C3%A9%20Malraux%2C%2047300%20Villeneuve-sur-Lot!5e0!3m2!1sfr!2sfr!4v1697618056779!5m2!1sfr!2sfr" width="600" height="450" loading="lazy"></iframe>
                                    <div>
                                        <h2 className="text-center font-oswald text-[#FF6B5B] text-[20px] font-[500]">CONTACTEZ-NOUS</h2>
                                        <h1 className="text-center font-oswald mt-5 text-[40px] font-[500] text-[#716868] wavy leading-[45px]">Échangez avec Cococinelle</h1>

                                        <div className="mt-10 px-4 md:px-24 lg:px-24 xl:px-24 font-[500]">


                                            <div className="flex mb-8">
                                                <img src={Ico1} />
                                                <div className="ml-7">
                                                    <h1>cococinelle.crea@gmail.com</h1>
                                                    <div className="flex flex-row justify-center">
                                                        <img onClick={() => window.open('https://www.facebook.com/cococinelle.crea/')} className="cursor-pointer" src={Facebook} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="flex mb-8">
                                                <img src={Ico2} />
                                                <div className="ml-7">
                                                    <h1>14 RUE ANDRE MALRAUX, Villeneuve-sur-Lot,</h1>
                                                    <h1>Nouvelle-Aquitaine, France, 47300</h1>
                                                </div>
                                            </div>

                                            <div className="flex mb-8 h-full">
                                                <img src={Ico3} />
                                                <div className="ml-7 flex items-center">
                                                    <h1><a className="text-[#756969] font-[500] hover:font-[600] " href="tel:+33564580005">06 26 35 44 35</a></h1>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;
