import { getAllOrders } from "actions/orders";
import Prices from "components/Prices";
import { IMG_LINK } from "config/key";
import { useEffect, useState } from "react";
import Moment from 'moment'
import ReactPaginate from "react-paginate";
import orderStatusesData from '../../utils/statut.json';
import 'moment/locale/fr';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pdf from "utils/pdf";
import { useSelector } from "react-redux";
import { getStore } from "actions/store";
import PlaceIcon from "shared/NcImage/PlaceIcon";

import NcDropDown from "shared/NcDropDown/NcDropDown";

type OrderStatus = {
  icon: string;
  color: string;
  text: string;
};

const orderStatuses: Record<string, OrderStatus> = orderStatusesData;


const AccountOrder = () => {
  const [orders, setOrders]: any = useState([]);
  const [showDropdown, setShowDropdown]: any = useState([]);
  const [store, setStore]: any = useState();
  const { auth }: any = useSelector(state => state);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [showMoreArray, setShowMoreArray]: any = useState([]);
  Moment.locale('fr');

  // PAGINATION
  let itemsPerPage = 6;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = orders?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(orders?.length / itemsPerPage);
  // PAGINATION 

  useEffect(() => {

    const fetchData = async () => {

      await getAllOrders().then((data: any) => {
        setLoadingSpinner(false);
        setOrders(data.reverse());
      })
    }

    fetchData()
  }, [])

  const handlePagePaginateClick = (event: any) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const newOffset = (event.selected * itemsPerPage) % orders?.length;

    setItemOffset(newOffset);
  };

  const handlePushShowMore = (id: string) => {
    setShowMoreArray((state: any): any => [...state, id]);

    if (showMoreArray.includes(id)) {
      setShowMoreArray((prevState: any) => {
        return prevState.filter((item: any) => item !== id)
      })
    }
  }

  useEffect(() => {
    getStore().then((res: any) => {
      setStore(res)
    })

  }, [])

  const generateFormPdf = (orderIndex: number) => {
    const orderData = currentItems[orderIndex];

    const processedData = {
      id: orderData?._id,
      cart: orderData?.cart,
      discount: orderData?.discount,
      order: orderData,
      user: auth?.user,
      store: store
    };

    return <Pdf {...processedData} />;
  };

  const handleShowDropdown = (id: any) => {
    if (showDropdown.includes(id)) {
      // Supprimer l'ID du tableau d'état
      const updatedDropdown = showDropdown.filter((item: any) => item !== id);
      setShowDropdown(updatedDropdown);
    } else {
      setShowDropdown([...showDropdown, id]);
    }
  }

  return (
    <>
      <div className="w-full h-full" onClick={() => setShowDropdown([])} />

      <Helmet>
        <title>Mes commandes | Alcools Bio.fr</title>
      </Helmet>

      <div className={"container py-14"}>
        <div className="flow-root">
          <div className="flex justify-center flex-col lg:px-28">
            <div className="flex flex-row gap-1 font-[500] text-[13px]">
              <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
              <span>{">"}</span>
              <a href="">Mes commandes</a>
            </div>

            <div className="mt-16 mb-16">
              <div className="flex w-full">
                <div className="text-2xl sm:text-3xl mb-8 font-semibold flex flex-row">
                  <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </Link>
                  <h1 className="ml-4">Mes commandes</h1>
                </div>
              </div>

              {loadingSpinner === false &&
                <>
                  {currentItems.length === 0 &&
                    <div className="text-center mt-10">
                      Aucune commande
                    </div>
                  }
                </>
              }
              {loadingSpinner &&
                <div className="flex justify-center">
                  <svg className="w-12 h-12 animate-spin text-[#FF6B5B]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </div>
              }

              {Object.keys(currentItems).map((order: any, index: number) => (
                <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 mt-6" key={index}>
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                      <div>
                        <div className="flex flex-row">
                          <p className="text-lg font-semibold capitalize">Commande N° {Moment(currentItems[index]?.createdAt).format("YYYY")}{Moment(currentItems[index]?.createdAt).format("MM")}-{currentItems[index]?.publicOrderId}</p>
                          <span style={{ background: orderStatuses[currentItems[order]?.status[currentItems[order]?.status?.length - 1]?.status]?.color }} className={`py-1 px-2 text-sm ml-2 rounded text-white`}>{orderStatuses[currentItems[order]?.status[currentItems[order]?.status?.length - 1]?.status]?.text}
                          </span>
                        </div>
                        <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                          <span>Date: {Moment(currentItems[index]?.createdAt).format("LLL")}</span>
                        </p>
                      </div>


                      <div className="flex  mt-3 md:mt-0 lg:mt-0 xl:mt-0 flex-row h-full justify-start md:justify-end lg:justify-end xl:justify-end">

                        {(
                          currentItems[order]?.status[currentItems[order]?.status?.length - 1]?.status === "delivery_on_road" ||
                          currentItems[order]?.status[currentItems[order]?.status?.length - 1]?.status === "delivery_delivered"
                        ) && (
                            <>
                              {Object.keys(currentItems[order]?.labelReturn?.ParcelData).length === 1
                                ? <>{currentItems[order]?.labelReturn?.ParcelData[0]?.TrackID && (
                                  <div className="mr-3">
                                    <span
                                      onClick={() =>
                                        window.open(
                                          `https://gls-group.com/FR/fr/suivi-colis?match=${currentItems[order]?.labelReturn?.ParcelData[0]?.TrackID}`,
                                          "_blank"
                                        )
                                      }
                                      className="text-[white] hover:bg-indigo-700 w-full whitespace-nowrap cursor-pointer focus:outline-none border border-indigo-500 focus:shadow-outline-gray bg-indigo-500 transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center"
                                    >
                                      Suivre mon colis
                                    </span>
                                  </div>
                                )}</>
                                :
                                <div>
                                  <NcDropDown
                                    renderTrigger={() => (
                                      <button id="dropdownDefaultButton" onClick={() => handleShowDropdown(currentItems[order]?._id)} data-dropdown-toggle="dropdown" className="mr-3 text-[white] hover:bg-indigo-700 whitespace-nowrap cursor-pointer focus:outline-none border border-indigo-500 px-4 focus:shadow-outline-gray bg-indigo-500 transition duration-150 ease-in-out h-8 rounded flex items-center justify-center"
                                        type="button">Suivre mes colis <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                          <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                        </svg></button>
                                    )}
                                    onClick={() => {

                                    }}
                                    data={currentItems[order]?.labelReturn?.ParcelData}
                                  />
                                </div>
                              }

                            </>
                          )}

                        <PDFDownloadLink className="mr-2"
                          document={generateFormPdf(index)}
                          fileName={`FACT-${Moment(currentItems[order]?.createdAt).format("YYYY") + Moment(currentItems[order]?.createdAt).format("MM") + '-' + currentItems[order]?.publicOrderId}.pdf`}
                        >
                          <button className="text-[black] hover:bg-[black] hover:text-[white] w-full whitespace-nowrap cursor-pointer focus:outline-none border border-[black]  focus:shadow-outline-gray bg-[transparent] transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                            Facture
                          </button>
                        </PDFDownloadLink>
                        <div className="flex mt-[.4rem] flex-row">
                          <span className="mr-2 font-semibold">Total: </span>
                          <Prices
                            className="whitespace-nowrap"
                            key={index}
                            price={currentItems[order]?.totalPrice}
                          />
                        </div>
                      </div>

                    </div>




                  </div>




                  <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    {Object.keys(currentItems[order].cart.item).map((it: any, index: number) => (
                      <div key={index} className="p-3">
                        <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">




                          <div className="relative h-20 w-20 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                            <div key={index}>
                              {currentItems[order].cart.item[it]?.itemRef?.images?.thumbnailId
                                ?
                                <>
                                  <img
                                    className="h-full w-full "
                                    src={IMG_LINK + currentItems[order].cart.item[it]?.itemRef?.images?.thumbnailId}
                                    alt={currentItems[order].cart.item[it]?.itemRef?.images?.thumbnailId}
                                  />
                                </>
                                :
                                <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                                  <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                                    <div className="h-2/4 max-w-[50%]">
                                      <PlaceIcon />
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>

                          </div>



                          <div className="ml-4 flex flex-1 flex-col">
                            <div>
                              <div className="flex justify-between">
                                <div>
                                  <h3 className="text-base font-medium line-clamp-1">{currentItems[order].cart.item[it]?.name}</h3>
                                  {currentItems[order].cart.item[it]?.itemRef?.properties &&
                                    <>
                                      {Object.keys(currentItems[order].cart.item[it]?.itemRef?.properties).map((propertie: any, index: number) => (
                                        <div className="mt-1 text-sm text-slate-500 dark:text-slate-400" key={index}>
                                          <div><span className="capitalize">{propertie}</span>: {currentItems[order].cart.item[it]?.itemRef.properties[propertie]}</div>
                                        </div>
                                      ))}
                                    </>
                                  }
                                  <p className="text-gray-500 dark:text-slate-400 flex items-center">
                                    <span className="hidden sm:inline-block">Quantité: </span>
                                    <span className="inline-block sm:hidden">x</span>
                                    <span className="ml-2">{currentItems[order].cart.item[it]?.quantity}</span>
                                  </p>
                                </div>
                                <div className="flex flex-row">
                                  {currentItems[order]?.cart?.item[it]?.priceWithDiscount !== undefined && currentItems[order]?.cart?.item[it]?.priceWithDiscount !== currentItems[order]?.cart?.item[it]?.price ?
                                    <>
                                      <div className="flex flex-row">
                                        <div className="line-through mt-[.6rem] text-[#e74c3c] whitespace-nowrap">
                                          {currentItems[order]?.cart?.item[it]?.price} €
                                        </div>
                                        <div className="mr-2 relative top-[.06rem]">
                                          <Prices
                                            className="mt-2"
                                            key={index}
                                            price={currentItems[order]?.cart?.item[it]?.priceWithDiscount}
                                          />
                                        </div>

                                      </div>
                                    </>
                                    :
                                    <>
                                      {currentItems[order]?.cart?.item[it]?.rebatedPrice
                                        ? <div className="flex flex-row"><Prices
                                          key={index}
                                          contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                          price={currentItems[order]?.cart?.item[it]?.rebatedPrice}
                                        /><Prices
                                            key={index}
                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-red-500 line-through text-sm font-medium h-full"
                                            price={currentItems[order]?.cart?.item[it]?.price}
                                          /></div>
                                        : <Prices
                                          key={index}
                                          contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                          price={currentItems[order]?.cart?.item[it]?.price}
                                        />
                                      }
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-center flex-row">
                      <button onClick={() => handlePushShowMore(currentItems[order]?._id)} className="bg-gray-200 hover:bg-gray-300 text-xs py-2 px-4 rounded inline-flex items-center">
                        <svg className={`${showMoreArray.includes(currentItems[order]?._id) ? "rotate-180" : ""} `} xmlns="http://www.w3.org/2000/svg" fill="#000000" height="10px" width="10px" version="1.1" id="Layer_1" viewBox="0 0 330 330">
                          <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                        </svg>
                        <span className="ml-2">{showMoreArray.includes(currentItems[order]?._id) ? 'Réduire' : 'Voir le détail'}</span>
                      </button>

                    </div>
                    {showMoreArray.includes(currentItems[order]?._id) &&
                      <div className="relative overflow-x-auto w-full mt-8">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="text-center">
                              <th scope="col" className="px-6 py-3">
                                Adresse de livraison
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Adresse de facturation
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Mode d'expédition
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td className="px-6 py-4">
                                {currentItems[order]?.delivery?.delivery?.relayPoint !== true
                                  ?
                                  <>
                                    <span>{currentItems[order]?.shippingAddress?.address}</span><br />
                                    <span>{currentItems[order]?.shippingAddress?.city} {currentItems[order]?.shippingAddress?.postalCode}, {currentItems[order]?.shippingAddress?.country}</span>
                                  </>
                                  :
                                  <>
                                    {!currentItems[order]?.delivery?.relayPointInfo?.ParcelShopID
                                      ? <>
                                        <span>
                                          <span>
                                            {currentItems[order]?.delivery?.relayPointInfo[0]?.LgAdr1}{currentItems[order]?.delivery?.relayPointInfo[0]?.LgAdr1 && ', '}
                                            {currentItems[order]?.delivery?.relayPointInfo[0]?.LgAdr2}{currentItems[order]?.delivery?.relayPointInfo[0]?.LgAdr2 && ' , '}
                                            {currentItems[order]?.delivery?.relayPointInfo[0]?.LgAdr3}
                                          </span>
                                        </span>
                                        <span>{currentItems[order]?.delivery?.relayPointInfo[0]?.Ville} {currentItems[order]?.delivery?.relayPointInfo[0]?.CP}, {currentItems[order]?.delivery?.relayPointInfo[0]?.Pays}</span>
                                      </>
                                      : <>
                                        {currentItems[order]?.delivery?.relayPointInfo?.Address?.Name1},
                                        {" "}{currentItems[order]?.delivery?.relayPointInfo?.Address?.Street},
                                        {" "}{currentItems[order]?.delivery?.relayPointInfo?.Address?.ZIPCode},
                                        {" "}{currentItems[order]?.delivery?.relayPointInfo?.Address?.CountryCode}
                                      </>
                                    }
                                  </>
                                }
                              </td>
                              <td scope="row" className="px-6 py-4">
                                <span>{currentItems[order]?.billingAddress?.address}</span><br />
                                <span>{currentItems[order]?.billingAddress?.city} {currentItems[order]?.billingAddress?.postalCode}, {currentItems[order]?.billingAddress?.country} </span>
                              </td>
                              <td className="px-6 py-4">
                                <span>{currentItems[order]?.delivery?.delivery?.name}</span><br />
                                <span>{currentItems[order]?.delivery?.delivery?.description}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mt-4">
                          <div className="col-span-2"></div>

                          <div className="relative overflow-x-auto ">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                              <tbody>

                                <tr className="bg-white dark:bg-gray-800">
                                  <td scope="row" className="px-6 py-4 text-gray-700 bg-gray-50 font-bold text-xs">
                                    Sous-total
                                  </td>
                                  <td className="px-6 py-2">
                                    {currentItems[order]?.discount ? currentItems[order]?.discountedPrice.toFixed(2) : currentItems[order]?.price.toFixed(2)} €
                                  </td>
                                </tr>
                                <hr />

                                <tr className="bg-white dark:bg-gray-800">
                                  <td scope="row" className="px-6 py-4 text-gray-700 bg-gray-50 font-semibold text-xs">
                                    Frais de port
                                  </td>
                                  <td className="px-6 py-2 border-t-[1px]">
                                    {currentItems[order]?.deliveryPrice.toFixed(2)} Є
                                  </td>
                                </tr>
                                <hr />
                                <tr className="bg-white dark:bg-gray-800">
                                  <td scope="row" className="px-6 py-4 text-gray-700 bg-gray-50 font-semibold text-xs">
                                    Total HT
                                  </td>
                                  <td className="px-6 py-2 border-t-[1px]">
                                    {currentItems[order]?.priceWithoutTaxe.toFixed(2)} Є
                                  </td>
                                </tr>
                                <hr />
                                <tr className="bg-white dark:bg-gray-800">
                                  <td scope="row" className="px-6 py-4 text-gray-700 bg-gray-50 font-bold text-xs">
                                    Dont TVA
                                  </td>
                                  <td className="px-6 py-2 border-t-[1px]">
                                    {(currentItems[order]?.taxePrice)?.toFixed(2)} Є
                                  </td>
                                </tr>
                                <hr />
                                <tr className="bg-white dark:bg-gray-800">
                                  <td scope="row" className="px-6 py-4 text-gray-700 bg-gray-50 font-bold text-xs">
                                    Total TTC
                                  </td>
                                  <td className="px-6 py-2 border-t-[1px]">
                                    {/* {(currentItems[order]?.price + currentItems[order]?.deliveryPrice)?.toFixed(2)} Є */}
                                    {currentItems[order]?.totalPrice} €

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>

                      </div>
                    }
                  </div>
                </div>
              ))}
              {orders.length !== 0 &&
                <div className="mt-5">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePagePaginateClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    containerClassName={'containerPaginate'}
                    previousClassName="previousBtnPaginate"
                    nextClassName="nextBtnPaginate"
                    activeClassName="activeBtnPaginate"
                    pageClassName="pageBtnPaginate"
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountOrder;
