import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { login } from "actions/auth";
import { getAllProductsInWishlist } from "actions/wishlist";
import { getProductInCart } from "actions/cart";
import { getUserInfos } from "actions/user";
import { NotificationManager } from 'react-notifications';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  let history: any = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location: any = useLocation();


  const handleLogin = async (email: string, password: string): Promise<void> => {
    try {
      await dispatch(login(email, password));
      await dispatch(getAllProductsInWishlist());
      await dispatch(getUserInfos());
      await dispatch(getProductInCart());
  
      const previousPath = location?.state?.from;
  
      if (previousPath === '/cart' || previousPath === '/checkout/information') {
        history.replace(previousPath);
      } else {
        history.replace('/customer/overview');
      }
    } catch (err: any) {
      NotificationManager.error(err.response?.data?.error, '');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleLogin(email, password);
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Connexion | CococinelleCococinelle</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Connexion
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Adresse email
            </span>
            <Input
              type="email"
              placeholder="example@example.com"
              onKeyDown={handleKeyDown}
              className="mt-1"
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </label>
          <label className="block">
            <span className="flex items-center text-neutral-800 dark:text-neutral-200">
              Mot de passe
            </span>
            <Input
              type="password"
              placeholder="**********"
              onKeyDown={handleKeyDown}
              onChange={(e: any) => setPassword(e.target.value)}
              className="mt-1"
            />
          </label>
          
          <div className="flex justify-end">
            <ButtonPrimary onClick={() => handleLogin(email, password)} type="submit">Se connecter</ButtonPrimary>
          </div>

          <span className="block text-center text-neutral-700 dark:text-neutral-300 ">
          Vous n'avez pas de compte? {` `}
            <Link className="text-green-600 hover:underline whitespace-normal inline-block" to="/signup">
              Créer un compte
            </Link>
          </span>
          <div onClick={() => history.push({ pathname: '/forgot-pass', state: { email: email } })} className="cursor-pointer text-center hover:underline text-green-600">
            Mot de passe oublié?
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
