import { getAnonymousCart, getProductInCart } from "actions/cart";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product } from "data/data";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { IMG_LINK } from "../../config/key";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { getStripePublicKey } from "actions/stripe";
import { NotificationManager } from 'react-notifications';
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import paypalImg from "images/paypal.svg";

const CheckoutPayment = () => {
    const [cart, setCart]: any = useState<Array<Product>>([]);
    const [successDeleteProductInCart, setSuccessDeleteProductInCart] = useState(false)
    const dispatch = useDispatch();
    const location: any = useLocation()
    const [order, setOrder]: any = useState([]);
    const { auth }: any = useSelector(state => state);
    const clientSecret = location?.state?.secretKey;
    const orderSummary = location?.state?.orderSummary;
    const deliveryPrice = location?.state?.deliveryPrice;
    const totalOrder = location?.state?.totalOrder;
    const [stripePublicKey, setStripePublicKey] = useState("");
    const [collaspeOrderSummary, setCollaspeOrderSummary] = useState(false);

    const [chooseMethodPayment, setChooseMethodPayment] = useState("credit_card");

    const orderRecap = location?.state?.orderRecap;

    useEffect(() => {
        getStripePublicKey()
            .then((res) => {
                setStripePublicKey(res);
            })
            .catch(() => {
                NotificationManager.error("Une erreur est survenue lors de la récupération de votre clé Stripe !", '');
            })

        if (orderSummary) {
            setOrder(orderSummary)
        }
    }, [])

    useEffect(() => {
        function handleWindowResize() {
            const isMobile = window.innerWidth <= 768; // Largeur considérée comme mobile (ajustez cette valeur selon vos besoins)
            setCollaspeOrderSummary(!isMobile);
        }

        handleWindowResize(); // Vérifier l'état initial lors du rendu initial

        window.addEventListener('resize', handleWindowResize); // Ajouter un écouteur d'événement pour détecter les changements de taille d'écran

        return () => {
            window.removeEventListener('resize', handleWindowResize); // Nettoyer l'écouteur d'événement lors du démontage du composant
        };
    }, []);

    const stripePromise = loadStripe(stripePublicKey);

    const appearance = {
        theme: 'stripe',
        email: false,
        paymentMethodCategories: ['card'] // Vide le tableau des méthodes de paiement
    };

    const options: any = {
        clientSecret,
        appearance,
    };

    const cartAnonymous: any = useSelector(state => state)

    useEffect(() => {
        setSuccessDeleteProductInCart(false)
        if (auth?.token) {
            const fetchData = async () => {
                const data = await dispatch(getProductInCart());
                setCart(data);
            }

            fetchData();
        }
    }, [successDeleteProductInCart])


    useEffect(() => {
        if (cartAnonymous?.cart && !auth?.token) {
            dispatch(getAnonymousCart(cartAnonymous?.cart?._id)).then((res: any) => {
                setCart(res);
            })
        }
    }, [])

    const renderProduct = () => {
        return (
            <>
                {order?.item &&

                    <>
                        {Object.keys(order?.item).map((item: any, index: number) => (
                            <div
                                key={index}
                                className="relative flex py-8 sm:py-10 xl:py-4 first:pt-0 last:pb-0"
                            >
                                <div className="relative h-20 w-20 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                                    <div key={index}>
                                        {order?.item[item]?.itemRef?.images?.image1Id
                                            ?
                                            <>
                                                <img
                                                    className="h-full w-full "
                                                    src={IMG_LINK + order?.item[item]?.itemRef?.images?.image1Id}
                                                    alt={order?.item[item]?.name}
                                                />
                                            </>
                                            :
                                            <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                                                <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                                                    <div className="h-2/4 max-w-[50%]">
                                                        <PlaceIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>

                                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                                    <div>
                                        <div className="flex justify-between ">
                                            <div className="flex-[1.5] ">
                                                <h3 className="text-base font-semibold">
                                                    <Link to="/product-detail">{order?.item[item]?.name}</Link>
                                                </h3>
                                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                                    <div>
                                                        {order?.item[item]?.itemRef?.properties &&
                                                            <>
                                                                {Object.keys(order?.item[item]?.itemRef?.properties).map((propertie: any, index: number) => (
                                                                    <div key={index}>
                                                                        <div><span className="capitalize">{propertie}</span>: {order?.item[item]?.itemRef?.properties[propertie]}</div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="mt-1 flex justify-between w-full sm:hidden relative">
                                                    <span className="text-sm text-slate-600">Quantité: x{order?.item[item]?.quantity}</span>


                                                    <div className="sm:block text-center relative">
                                                        {order?.item[item]?.priceWithDiscount !== undefined && order?.item[item]?.priceWithDiscount !== order?.item[item]?.price ?
                                                            <>
                                                                <div className="flex flex-row">
                                                                    <div className="mr-2">
                                                                        <Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                            price={order?.item[item]?.priceWithDiscount}
                                                                        />
                                                                    </div>
                                                                    <div className="line-through mt-[.1rem] text-[#e74c3c]">
                                                                        {order?.item[item]?.price} €
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {order?.item[item]?.rebatedPrice
                                                                    ? <div className="flex flex-row"><Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={order?.item[item]?.rebatedPrice}
                                                                    /><Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-red-500 line-through text-sm font-medium h-full"
                                                                            price={order?.item[item]?.price}
                                                                        /></div>
                                                                    : <Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={order?.item[item]?.price}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hidden sm:block text-center relative">
                                                <NcInputNumber className="relative z-10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        ))}
                    </>
                }
            </>

        );
    };

    const handleCollapseOrderSummary = () => {
        setCollaspeOrderSummary(true)

        if (collaspeOrderSummary) {
            setCollaspeOrderSummary(false);
        }
    }

    const handleChoosePaymentMethod = (methodPayment: any) => {
        setChooseMethodPayment(methodPayment);
    }

    return (
        <div className="nc-CartPage">
            <Helmet>
                <title>Tunnel d'achat | CococinelleCococinelle</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div className="mb-12 sm:mb-16">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                        Tunnel d'achat
                    </h2>
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        Informations
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        Livraison
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">Paiement</span>
                    </div>
                </div>

                <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 hidden lg:block xl:block" />


                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-[60%] xl:w-[55%] order-2 lg:order-none xl:order-none">


                        <div className="mb-6 mt-6 md:mt-0 lg:mt-0 xl:mt-0">
                            <h1 className="font-semibold text-[18px]">Méthode de paiement</h1>
                        </div>
                        <div className="grid grid-cols-1">


                            <div onClick={() => handleChoosePaymentMethod('credit_card')} className="border-[1px] p-4 flex items-center cursor-pointer">
                                <input type="radio" checked={chooseMethodPayment === 'credit_card'} />
                                <div className="ml-4 flex justify-between mt-1 w-full">
                                    <span className="mt-1 mr-3">Payer par carte bancaire</span>
                                    <div className="flex justify-end">
                                        <img width={45} className="ml-[4px]" src={mastercardImg} />
                                        <img width={45} className="ml-[5px]" src={visaImg} />
                                    </div>
                                </div>
                            </div>


                            {chooseMethodPayment === 'credit_card' &&
                                <div className="border-l-[1px] border-r-[1px] border-b-[1px] p-4">
                                    {clientSecret && (
                                        <Elements options={options} stripe={stripePromise}>
                                            <CheckoutForm order={orderRecap} deliveryPrice={deliveryPrice} />
                                        </Elements>
                                    )}
                                </div>
                            }


                            {/* <div className="border-[1px] p-4 flex items-center mt-4 cursor-pointer w-full cursor-not-allowed bg-gray-100/100">
                                <input type="radio" checked={chooseMethodPayment === 'paypal'} disabled/>
                                <div className="ml-4 flex justify-between mt-1 w-full">
                                    <span className="mr-3">Payer par PayPal</span>
                                    <div className="flex justify-end">
                                        <img width={100} className="ml-[4px]" src={paypalImg} />
                                    </div>
                                </div>
                            </div>
                            <div className="border-[1px] p-4 flex items-center mt-4 cursor-not-allowed bg-gray-100/100">
                                <input type="radio" checked={chooseMethodPayment === 'bank_transfer'} disabled/>
                                <span className="ml-4 mt-[.1rem]">Payer par virement bancaire</span>
                            </div> */}
                        </div>



                    </div>

                    <>
                        <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-0 lg:my-0 lg:mx-10 flex-shrink-0 mb-10"></div>
                        <div className="flex-1 order-1 lg:order-none xl:order-none">
                            <div className="sticky top-28">
                                <h3 className="text-lg font-semibold mb-5">Récapitulatif de la commande</h3>

                                <div className="border-[1px] rounded p-3">
                                    <div className="flex justify-between font-[400]">
                                        <span className="text-slate-500">{cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"} dans votre panier - {cart?.discount ? cart?.discountedPrice?.toFixed(2) : cart?.cartPrice?.toFixed(2)} €</span>
                                        <span onClick={() => handleCollapseOrderSummary()} className="font-[500] text-primary-6000 text-[14px] hover:underline hover:text-primary-500 cursor-pointer">{collaspeOrderSummary ? "Fermer le détail" : "Voir le détail"}</span>
                                    </div>
                                </div>

                                {collaspeOrderSummary &&
                                    <div className="mt-8">
                                        {renderProduct()}

                                        <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80 block lg:hidden xl:hidden">

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-5">Sous-total ({order?.cartLength} {order?.cartLength === 1 ? "article" : "articles"})</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                    {order?.discountedPriceWithoutTaxe ? order?.discountedPriceWithoutTaxe : order?.cartPriceWithoutTaxe?.toFixed(2)} €
                                                </span>
                                            </div>
                                            {order.discount &&
                                                <div className="flex justify-between pb-4">
                                                    <span className="mt-4">Remise ({order?.discount?.discountCode})</span>
                                                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                        - {order?.discount?.amount}
                                                        {order?.discount?.type === "percentage" ? "%" : "€"}
                                                    </span>
                                                </div>
                                            }

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-5">Livraison</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                    {deliveryPrice} €
                                                </span>
                                            </div>

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-4">Dont TVA</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                    {(order?.taxePrice)?.toFixed(2)} €
                                                </span>
                                            </div>


                                            <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                                <div>
                                                    <span>Total de la commande</span>
                                                    <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                                                </div>

                                                {((Number(order?.discount?.amount ? (order?.discountedPrice)?.toFixed(2) : cart?.cartPrice)) + Number(deliveryPrice))?.toFixed(2)}

                                            </div>

                                        </div>
                                    </div>
                                }

                                <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80 hidden lg:block xl:block">

                                    <div className="flex justify-between pb-4">
                                        <span className="mt-5">Sous-total ({order?.cartLength} {order?.cartLength === 1 ? "article" : "articles"})</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                            {order?.discountedPriceWithoutTaxe ? order?.discountedPriceWithoutTaxe : order?.cartPriceWithoutTaxe?.toFixed(2)} €
                                        </span>
                                    </div>
                                    {order.discount &&
                                        <div className="flex justify-between pb-4">
                                            <span className="mt-4">Remise ({order?.discount?.discountCode})</span>
                                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                - {order?.discount?.amount}
                                                {order?.discount?.type === "percentage" ? "%" : "€"}
                                            </span>
                                        </div>
                                    }

                                    <div className="flex justify-between pb-4">
                                        <span className="mt-5">Livraison</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                            {deliveryPrice} €
                                        </span>
                                    </div>

                                    <div className="flex justify-between pb-4">
                                        <span className="mt-4">Dont TVA</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                            {(order?.taxePrice)?.toFixed(2)} €
                                        </span>
                                    </div>


                                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                        <div>
                                            <span>Total de la commande</span>
                                            <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                                        </div>

                                        {((Number(order?.discount?.amount ? (order?.discountedPrice)?.toFixed(2) : cart?.cartPrice)) + Number(deliveryPrice))?.toFixed(2)}

                                    </div>
                                </div>

                                <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-0 lg:my-0 lg:mx-10 flex-shrink-0 mt-10"></div>

                            </div>
                        </div>
                    </>
                </div>
            </main>
        </div>
    );
};

export default CheckoutPayment;

