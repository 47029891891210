import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";
import { BORDERS, COLORS } from "config/theme";

export interface ButtonFourProps extends ButtonProps {}

const ButtonFour: React.FC<ButtonFourProps> = ({
  className = "border border-neutral-200",
  ...args
}) => {
  return <Button style={{ backgroundColor: COLORS?.buttonFour, border: '1px', borderRadius: BORDERS?.buttonFour, color: "black" }}
  className={`ttnc-ButtonThird ${className} text-[white]`} {...args} />;
};

export default ButtonFour;
